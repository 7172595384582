.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  h3 {
    font-size: 29px;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    margin: 0px;
  }

  h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
    margin: 32px 0 0 0;
  }

  h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    margin: 16px 0 0 0;
  }

  .emoji {
    font-size: 141.339px;
    line-height: 166px;
    margin-top: 32px;
  }
}
