.wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}
.titleBox {
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    margin: 0;
  }
  h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
  }
}

.arrow {
  height: 18px;
  width: 22px;
  margin: 6px 19px 6px 11px;
  cursor: pointer;
}

.showSections {
  height: 36px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  h5 {
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: #ffdc81;
    cursor: pointer;
  }
}
