.tags {
  display: flex;
  max-width: 247px;

  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:first-child)::before {
      content: '|';
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
