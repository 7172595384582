.tag {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  margin-top: 3px;
  padding: 4px 6.5px;
  border-radius: 3px;
}
.tagIcon {
  height: 17px;
  margin-right: 6px;
}
