.metadata {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 15px;

  .metadataWrapper {
    display: flex;
    flex-direction: row;
    margin-right: 8px;
    align-items: center;
  }

  .icon {
    width: 17px;
    height: 17px;
    margin-right: 4px;
  }

  h5 {
    font-size: 15px;
    font-weight: 700;
    line-height: 13px;
    vertical-align: center;
  }
}
