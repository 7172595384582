.searchWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h2,
  h4 {
    margin: 0;
  }
}

.searchTerm {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}

.results {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
}
