.section {
  display: flex;
  justify-content: space-between;
  padding: 16px 0px 16px 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  cursor: pointer;

  &.expanded {
    border-bottom: 1px solid #fff;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    div > h5 {
      margin-top: 0px;
    }
  }
}

.arrow {
  margin: 0 15px 0 6px;
  height: 14px;
  width: 8px;
  transition: all 150ms ease-in-out;

  &.rotate {
    rotate: 90deg;
  }
}

.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
}

.expandedSection {
  transform: scaleY(1);
  transform-origin: top;
  transition: all 150ms ease-in-out;

  &.hidden {
    transform: scaleY(0);
  }
}
