.root {
  position: fixed;
  top: 0px;
  left: 0px;
  height: calc(100vh - 14px);
  width: calc(100vw - 14px);
  z-index: 11;
  background-color: #fff;
  border-radius: 20px;
  margin: 7px;
  overflow: scroll;
}

.header {
  padding: 24px 24px 36px 24px;
  background-position: center;
  background-size: cover;
  border-radius: 16px 16px 0px 0px;

  h1,
  h2,
  h4,
  h5,
  h6 {
    margin: 0px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .back {
    height: 36px;
    width: 30px;
    cursor: pointer;
  }

  .actionButtons {
    display: flex;
    flex-direction: row;
  }

  .openOriginal {
    display: flex;
    flex-direction: row;
    text-align: right;
    align-items: center;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid white;

    .openOriginalIcon {
      height: 15px;
      width: 15px;
      margin-left: 12px;
    }

    span {
      color: #fff;
      margin: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      white-space: pre-line;
      align-self: center;
    }
  }

  .contentType {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 39.5px;
    padding: 4px 6px;
    border: 1px solid #fff;
    border-radius: 3px;
    width: fit-content;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }

  .title {
    margin-top: 16px;
    line-height: 36px;
  }

  .subtitle {
    margin-top: 8px;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
  }

  .metadata {
    color: #fff;
    margin-top: 8px;
    font-weight: 400;
    font-size: 11.5px;
    line-height: 19px;
    a {
      color: #a0e1f6;
      font-weight: 500;
    }
  }
}

.tags {
  display: flex;
  flex-direction: row;
  margin-top: 16px;

  h6 {
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    margin: 0px;
  }

  h5 {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #2b5889;
    margin: 0px;
  }

  .tagContainer {
    display: flex;
    flex-direction: column;
    margin-right: 19.5px;
  }
}

.content {
  color: #000;
}
