.root {
  height: 51px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0px 24px;
  cursor: pointer;

  &:focus-within {
    background-color: #fff;
  }
}

.lightBackground {
  background-color: #fff;
}

.input {
  background: none;
  border: none;
  height: 100%;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: #fff;
    font-weight: 400;
  }
  &:focus-within::placeholder {
    color: rgba(0, 0, 0, 0.23);
    font-weight: 500;
  }
}

.icon {
  height: 17px;
  width: 17px;
  margin: 17px 0px;
  fill: #fff;
}

.root:focus-within .icon,
.darkIcon {
  fill: #000;
}

.description {
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-top: 8px;
}
