.item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 10px 7px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;

  &.clickable {
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .withTags {
      align-items: flex-start;
      div > h4 {
        margin-bottom: 8px;
      }
    }
  }
  .icon {
    margin-right: 12px;
  }

  h4 {
    margin: 0px;
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
    color: #fff;
  }

  .arrow {
    opacity: 0.5;
    height: 15px;
    width: 11px;
  }
}

.regular {
  margin-left: 0px;
}

.indented {
  margin-left: 65px;
}

.newTabIcon {
  height: 15px;
  width: 15px;
  opacity: 0.5;
}

.alignCenter {
  display: flex;
  align-self: center;
}
