.link {
  display: flex;
  color: #fff;
  text-decoration: none;
  text-align: end;

  .redirectIcon {
    margin-left: 8px;
    height: 12.25px;
    width: 14px;
    align-self: center;
  }

  h5 {
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    white-space: pre-line;
    align-self: center;
  }
}
