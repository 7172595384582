// @import 'basics';

//TODO extract to shared styles

.contentContainer {
  font-family: 'Raleway', sans-serif;
  padding: 24px;
  font-size: 14px;
  color: #000;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    color: unset;
    display: block;
    font-weight: bold;
    font-size: unset;
    line-height: unset;
    text-align: unset;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h1 {
    font-size: 24px;
    font-weight: 800;
    line-height: 25.92px;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }
  h2 {
    font-size: 22px;
    font-weight: 800;
    line-height: 23.1px;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }
  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
  }
  h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
  }
  pre {
    font-family: monospace;
    white-space: pre;
    margin: 1em 0px;
  }
  p,
  li {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  a {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    color: #2385f8;
  }
  ol {
    display: block;
    list-style-type: revert;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  th,
  tr:first-of-type {
    background-color: #2b5889;
    color: #fff;
    font-weight: 700;
  }

  td {
    border: 1px solid #d3d3d3;
    height: 22px;
    vertical-align: top;
    padding: 8px;
  }

  //offset for table of contents scrollIntoView
  h1,
  h2,
  h3 {
    padding-top: 16px;
    margin-top: -16px;
  }

  img {
    max-width: 100% !important;
    height: auto !important;
  }
}
