.title {
  margin-top: 16px !important;
  font-size: 24px;
  font-weight: 800;
  line-height: 26px;
}
.subtitle {
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
}
